<template>
  <div id="result">
    <h1>Ось твій результат.</h1>
    <div class="row">
      <p class="result-text">{{ score }} / {{ maxScore || '--' }}</p>
      <div class="result-box">
        <div class="result-line" :style="{ width: scoreWidth }"></div>
      </div>
      <p>
        Тест пройдено за {{ duration }}. <br />
      </p>
    </div>
    <button @click="$emit('restartTest')">Почати спочатку</button>
  </div>
</template>

<script>
export default {
  name: 'Result',
  props: {
    score: Number,
    maxScore: Number,
    duration: [String, Number]
  },
  computed: {
    scoreWidth () {
      return this.score / this.maxScore * 100 + '%'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  display: block;
  width: 100%;
}
#result {
  font-size: 2rem;
  margin: 6vh auto;
  width: 86vw;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: 86vh;
  h1 {
    margin-top: 0;
    font-weight: 600;
    line-height: 1.15em;
    width: 100%;
    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
  }
  p {
    width: 100%;
    font-size: 0.5em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 3em;
    &.result-text {
      font-size: 0.625em;
      line-height: 1.5em;
      margin-top: 0;
      margin-bottom: 0.25em;
    }
  }
  button {
    border: 0;
    padding: 0.5em 1.5em;
    background: #eee;
    display: inline-block;
    line-height: 1.5em;
    font-size: inherit;
    font-weight: 300;
    font-family: inherit;
    color: #363636;
    border-radius: 0;
    cursor: pointer;
    &:focus,
    &:hover {
      outline: none;
      background-color: #ccc;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
    }
  }
  .result-box {
    height: 1em;
    background: hsl(0, 0%, 97%);
    margin-bottom: 0.5em;
    width: 100%;
  }
  .result-line {
    height: 100%;
    background: #bebebe;
  }
}
</style>
