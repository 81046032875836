<template>
  <div id="test">
    <header id="header">
      <h5 id="style-name">{{ allImages[currentPairIndex].style }}</h5>
    </header>
    <div id="option-a" class="option">
      <OptionalImage
        :src="'/images/' + this.currentPair[randomBool]"
        @pick="pickOption(randomBool)"
      />
    </div>
    <div id="option-b" class="option">
      <OptionalImage
        :src="'/images/' + this.currentPair[randomBool2]"
        @pick="pickOption(randomBool2)"
      />
    </div>
    <!-- <div class="progress-bar">
      <div
        id="progress-time"
        :class="['progress-state', { hurry: hurry && timeCount > 5000 }]"
        :style="{ width: progressTimeWidth + '%' }"
      />
    </div>
    <div class="progress-bar">
      <div
        id="progress-test"
        class="progress-state"
        :style="{ width: progressWidth + '%' }"
      />
    </div> -->
  </div>
</template>

<script>
import OptionalImage from '@/components/OptionalImage.vue'

export default {
  components: {
    OptionalImage
  },
  name: 'Test',
  data () {
    return {
      allImages: [
        {
          style: 'Art Deco',
          options: [
            ['art-deco-3.jpg'],
            ['art-deco-1.jpg', 'art-deco-2.jpg']
          ]
        },
        {
          style: 'Asian',
          options: [
            ['asian-1.jpg'],
            ['asian-2.jpg', 'asian-3.jpg']
          ]
        },
        {
          style: 'Classic',
          options: [
            ['classic-1.jpg', 'classic-3.jpg'],
            ['classic-2.jpg', 'classic-4.jpg']
          ]
        },
        {
          style: 'Coastal',
          options: [
            ['coastal-1.jpg', 'coastal-3.jpg'],
            ['coastal-2.jpg', 'coastal-4.jpg']
          ]
        },
        {
          style: 'Contemporary',
          options: [
            ['contemporary-1.jpg', 'contemporary-4.jpg'],
            ['contemporary-2.jpg', 'contemporary-3.jpg']
          ]
        },
        {
          style: 'Eclectic',
          options: [
            ['eclectic-1.jpg', 'eclectic-2.jpg'],
            ['eclectic-3.jpg', 'eclectic-4.jpg']
          ]
        },
        {
          style: 'French Country',
          options: [
            ['french-country-1.jpg'],
            ['french-country-2.jpg']
          ]
        },
        {
          style: 'Industrial',
          options: [
            ['industrial-1.jpg', 'industrial-4.jpg'],
            ['industrial-2.jpg', 'industrial-3.jpg']
          ]
        },
        {
          style: 'Luxury',
          options: [
            ['luxury-1.jpg'],
            ['luxury-2.jpg']
          ]
        },
        {
          style: 'Mediterranean',
          options: [
            ['mediterranean-1.jpg', 'mediterranean-3.jpg'],
            ['mediterranean-2.jpg', 'mediterranean-4.jpg']
          ]
        },
        {
          style: 'Mid-Century Modern',
          options: [
            ['mid-century-modern-1.jpg', 'mid-century-modern-3.jpg'],
            ['mid-century-modern-2.jpg', 'mid-century-modern-4.jpg', 'mid-century-modern-5.jpg']
          ]
        },
        {
          style: 'Minimalist',
          options: [
            ['minimalist-1.jpg', 'minimalist-3.jpg'],
            ['minimalist-2.jpg', 'minimalist-4.jpg', 'minimalist-5.jpg']
          ]
        },
        {
          style: 'Modern',
          options: [
            ['modern-1.jpg', 'modern-3.jpg'],
            ['modern-2.jpg', 'modern-4.jpg']
          ]
        },
        {
          style: 'Modern Country',
          options: [
            ['modern-country-1.jpg', 'modern-country-3.jpg'],
            ['modern-country-2.jpg', 'modern-country-4.jpg', 'modern-country-5.jpg']
          ]
        },
        {
          style: 'Rustic',
          options: [
            ['rustic-1.jpg', 'rustic-3.jpg'],
            ['rustic-2.jpg', 'rustic-4.jpg']
          ]
        },
        {
          style: 'Scandinavian',
          options: [
            ['scandinavian-1.jpg', 'scandinavian-3.jpg'],
            ['scandinavian-2.jpg', 'scandinavian-4.jpg']
          ]
        },
        {
          style: 'Traditional',
          options: [
            ['traditional-1.jpg', 'traditional-4.jpg'],
            ['traditional-2.jpg', 'traditional-3.png']
          ]
        },
        {
          style: 'Vintage',
          options: [
            ['vintage-1.jpg'],
            ['vintage-2.jpg']
          ]
        }
      ],
      pairs: [],
      currentPairIndex: 0,
      currentPairUrls: [],
      currentPairImages: [],
      score: 0,
      randomBool: Math.round(Math.random()),
      loaded: false,
      start: new Date(),
      // totalDuration: 5 * 60 * 1000,
      timeCount: 0,
      intervalId: 0
    }
  },
  computed: {
    randomBool2 () {
      return this.randomBool ? 0 : 1
    },
    pairsCount () {
      return this.pairs.length
    },
    currentPair () {
      return this.pairs[this.currentPairIndex]
    },
    maxScore () {
      return this.pairs.length || 0
    }
    // progressWidth () { return 100 * this.currentPairIndex / this.pairsCount },
    // progressTimeWidth () { return 100 * this.timeCount / this.totalDuration },
    // hurry () { return this.progressWidth < this.progressTimeWidth }
  },
  methods: {
    pickOption (answer) {
      this.score += answer
      if (this.currentPairIndex < this.pairsCount - 1) { this.currentPairIndex++ } else {
        this.fanlizeTest()
      }
      this.randomBool = Math.round(Math.random())
    },
    shuffle (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]]
      }
      return a
    },
    loadImage (url) {
      return new Promise(resolve => {
        const image = new Image()
        image.addEventListener('load', () => {
          resolve(image)
        })
        image.src = url
      })
    },
    fanlizeTest () {
      clearInterval(this.intervalId)
      const duration = new Date() - this.start
      this.$emit('endTest', { score: this.score, maxScore: this.maxScore, duration })
    }
  },
  created () {
    this.shuffle(this.allImages)
    for (let i = 0; i < this.allImages.length; i++) {
      this.pairs.push([
        this.shuffle(this.allImages[i].options[0])[0],
        this.shuffle(this.allImages[i].options[1])[0]
      ])
    }

    this.intervalId = setInterval(() => {
      this.timeCount += 10
      // if (this.timeCount > this.totalDuration) {
      //   clearInterval(this.intervalId)
      //   this.fanlizeTest(false)
      // }
    }, 10)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#test {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-areas:
    'header header'
    'option-a option-b';
  grid-template-rows: 48px calc(100% - 48px);
  grid-template-columns: 50% 50%;
  overflow: hidden;
  background: #0b1015;
  position: relative;
  @media (max-aspect-ratio: 1/1) {
    grid-template-areas:
      'option-a'
      'header'
      'option-b';
    grid-gap: 0;
    grid-template-rows: calc(50% - 24px) 48px calc(50% - 24px);
    grid-template-columns: 100%;
  }
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
#option-a {
  padding-right: 6px;
  @media (max-aspect-ratio: 1/1) {
    padding: 0;
  }
}
#option-b {
  padding-left: 6px;
  @media (max-aspect-ratio: 1/1) {
    padding: 0;
  }
}
#header {
  grid-area: header;
  @media (max-aspect-ratio: 1/1) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
#style-name {
  color: white;
  text-align: center;
  padding: 1em 2em;
  margin: 0;
}
#option-a {
  grid-area: option-a;
}
#option-b {
  grid-area: option-b;
}
// .progress-bar {
//   height: 3px;
//   width: 100%;
//   // overflow: hidden;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   z-index: 1;
// }
// .progress-state {
//   height: 100%;
// }
// #progress-time {
//   background: hsla(100, 100%, 70%, 0.9);
//   transform: translateY(-100%);
//   transition: height 0.5s ease-out;
//   &.hurry {
//     animation: hurry 1s infinite;
//     height: 200%;
//   }
// }
// #progress-test {
//   background: hsla(0, 0%, 100%, 1);
//   transition: width 500ms ease-in-out;
// }
// @keyframes hurry {
//   0% {
//     background: rgb(180, 0, 0);
//   }
//   50% {
//     background: rgb(210, 110, 0);
//   }
//   100% {
//     background: rgb(180, 0, 0);
//   }
// }
</style>
