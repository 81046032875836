<template>
  <form id="register" @submit="onSubmit">
    <input
      type="text"
      name="fname"
      placeholder="Ім’я"
      v-model="fname"
      autocomplete="off"
      :class="{ error: error.fname }"
    />
    <input
      type="text"
      name="lname"
      placeholder="Прізвище"
      v-model="lname"
      autocomplete="off"
      :class="{ error: error.lname }"
    />
    <select
      type="text"
      name="city"
      placeholder="Роль"
      v-model="city"
      :class="{ error: error.city, placeholder: !city }"
    >
      <option selected disabled value="">Філія</option>
      <option v-for="city in cities" :key="city" :value="city">{{
        city
      }}</option>
    </select>
    <select
      type="text"
      name="role"
      placeholder="Роль"
      v-model="role"
      :class="{ error: error.role, placeholder: !role }"
    >
      <option selected disabled value="">Роль</option>
      <option v-for="role in roles" :key="role" :value="role">{{
        role
      }}</option>
    </select>
    <select
      v-if="isSpec"
      type="text"
      name="competence"
      placeholder="Компетенція"
      v-model="competence"
      :class="{ error: error.competence, placeholder: !competence }"
    >
      <option selected disabled value="">Компетенція</option>
      <option
        v-for="competence in competences"
        :key="competence"
        :value="competence"
        >{{ competence }}</option
      >
    </select>
    <button type="submit">Почнімо</button>
  </form>
</template>

<script>
export default {
  name: 'Register',
  data () {
    return {
      fname: '',
      lname: '',
      role: '',
      roles: ['Візуалізатор', 'Моделювальник', 'Кресляр', 'Менеджер Проєктів', 'Менеджер Клієнтів', 'Інша роль'],
      competence: '',
      competences: ['Senior', 'Middle', 'Junior'],
      city: '',
      cities: ['Київ', 'Харків', 'Львів', 'Одеса', 'Дніпро'],
      error: {
        lname: false,
        fname: false,
        city: false,
        role: false,
        competence: false
      },
      timeout: 0
    }
  },
  computed: {
    isSpec () {
      return ['Візуалізатор', 'Моделювальник'].indexOf(this.role) !== -1
    }
  },
  watch: {
    isSpec (value) {
      if (!value) this.competence = ''
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.fname && this.lname && this.city && this.role && (this.competence || !this.isSpec)) {
        this.$emit('startTest', { name: this.lname + ' ' + this.fname, city: this.city, role: this.role, competence: this.competence })
      } else {
        if (!this.lname) {
          this.error.lname = true
        }
        if (!this.fname) {
          this.error.fname = true
        }
        if (!this.role) {
          this.error.role = true
        }
        if (!this.city) {
          this.error.city = true
        }
        if (this.isSpec && !this.competence) {
          this.error.competence = true
        }
        if (this.timeout) { clearTimeout(this.timeout) }
        this.timeout = setTimeout(() => {
          this.error.lname = false
          this.error.fname = false
          this.error.role = false
          this.error.city = false
          this.error.competence = false
        }, 3000)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#register {
  font-size: 2rem;
  text-align: center;
  margin: 6vh auto;
  width: 86vw;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: 86vh;
}
input {
  font-size: inherit;
  width: 100%;
  font-weight: 300;
  font-family: inherit;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 2px solid #bdbdbd;
  margin-bottom: 1em;
  line-height: 1.75em;
  &:focus {
    outline: none;
    border-bottom-color: #363636;
  }
  &.error {
    border-color: rgb(190, 20, 0);
  }
  &::placeholder {
    color: #a3a3a3;
    // text-align: left;
  }
}
button {
  border: 0;
  padding: 0.5em 1.5em;
  background: #eee;
  display: inline-block;
  line-height: 1.5em;
  font-size: inherit;
  font-weight: 300;
  font-family: inherit;
  color: #363636;
  border-radius: 0;
  cursor: pointer;
  &:focus,
  &:hover {
    outline: none;
    background-color: #ccc;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
}
#error {
  font-size: 0.5em;
  width: 100%;
}
select {
  width: 100%;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: 2em;
  color: inherit;
  border: none;
  border-bottom: 2px solid #bdbdbd;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  margin-bottom: 1em;
  line-height: 1.75em;
  &.placeholder {
    color: #a3a3a3;
  }
  &:focus {
    outline: none;
    outline: none;
    border-bottom-color: #363636;
  }
  &.error {
    border-color: rgb(190, 20, 0);
  }
  > option {
    &:first-child {
      display: none;
    }
    font-weight: 300;
  }
}
</style>
