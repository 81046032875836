<template>
  <div class="image-wrapper">
    <transition name="fade">
      <img
        :class="{ loaded }"
        :src="url"
        v-show="loaded"
        @load="onImageLoaded"
        @click="onClick"
      />
    </transition>
    <transition name="fade">
      <div v-show="!loaded" class="preloader">
        <span class="circle-one" />
        <span class="circle-two" />
        <span class="circle-thr" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OptionalImage',
  props: {
    src: String
  },
  data () {
    return {
      url: this.src,
      loaded: false
    }
  },
  watch: {
    src (newSrc) {
      this.loaded = false
      setTimeout(() => {
        this.url = newSrc
      }, 500)
    }
  },
  methods: {
    onClick () {
      if (this.loaded) {
        this.$emit('pick')
      }
    },
    onImageLoaded () {
      setTimeout(() => {
        this.loaded = true
      }, 500)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  &.loaded {
    pointer-events: all;
    cursor: pointer;
  }
}
.preloader {
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  & > span {
    width: 1rem;
    height: 1rem;
    margin: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    display: inline-block;
    animation: pulse 1s ease-in-out infinite;
    &:nth-child(1) {
      animation-delay: 0.33s;
    }
    &:nth-child(2) {
      animation-delay: 0.66s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
